import { AnalyticsEvent, SupportedProviders } from "./analytics.dto";
import { AmplitudeAnalyticsProvider } from "./providers/amplitude.provider";
import { AnalyticsProvider } from "./providers/base.provider";
import { FirebaseAnalyticsProvider } from "./providers/firebase.provider";

export class AnalyticsService {
  providers: AnalyticsProvider[];
  lastEvent: string = "";
  constructor() {
    console.log("<== Analytics Initialized ==>");
    this.providers = [
      new FirebaseAnalyticsProvider(),
      new AmplitudeAnalyticsProvider(),
    ];
  }

  sendEvent(event: AnalyticsEvent, providerName: SupportedProviders = "All") {
    console.log("Sending event", event);
    let providersToSend = this.providers;
    if (providerName !== "All") {
      providersToSend = this.providers.filter((provider) =>
        provider.constructor.name
          .toLowerCase()
          .includes(providerName.toLowerCase())
      );
    }
    providersToSend.forEach((provider) => provider.sendEvent(event));
  }

  setUserProperties(
    props: Record<string, string | number | boolean>,
    providerName: SupportedProviders = "All"
  ) {
    let providersToSend = this.providers;
    if (providerName !== "All") {
      providersToSend = this.providers.filter((provider) =>
        provider.constructor.name
          .toLowerCase()
          .includes(providerName.toLowerCase())
      );
    }
    providersToSend.forEach((provider) => provider.setUserProperties(props));
  }

  setUserId(userId: string, providerName: SupportedProviders = "All") {
    let providersToSend = this.providers;
    if (providerName !== "All") {
      providersToSend = this.providers.filter((provider) =>
        provider.constructor.name
          .toLowerCase()
          .includes(providerName.toLowerCase())
      );
    }
    providersToSend.forEach((provider) => provider.setUserId(userId));
  }
}
