import { User } from "firebase/auth";
import {
  DocumentData,
  Unsubscribe,
  collection,
  onSnapshot,
  getDocs,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import useFirebaseFirestore from "./useFirebaseFirestore";
import { useRouter } from "next/router";
import { ThreadStore } from "../utils/types";
import { useSession } from "../context/SessionContext";

const useFirebaseAgentsData = () => {
  const { db } = useFirebaseFirestore();
  const { user } = useSession();
  const [threadList, setThreadList] = useState<ThreadStore[]>([]);
  const [messagesList, setMessagesList] = useState<DocumentData[]>([]);
  const router = useRouter();
  const { agentId, id } = router.query;

  useEffect(() => {
    let threadsSubscription: Unsubscribe;
    if (id && user) {
      const messages = collection(
        db,
        `/profiles/${user.uid}/agents/${agentId}/messages`
      );
      const filteredMessages = query(
        messages,
        where("thread_id", "==", id),
        orderBy("timestamp", "desc")
      );
      getDocs(filteredMessages).then((snap) => {
        setMessagesList(
          snap.docs.map((document) => {
            return document.data();
          })
        );
      });
    }
    if (agentId && user) {
      const threads = collection(
        db,
        `/profiles/${user.uid}/agents/${agentId}/threads`
      );
      threadsSubscription = onSnapshot(threads, (snap) => {
        setThreadList(
          snap.docs.map((document) => {
            return document.data() as ThreadStore;
          })
        );
      });
    }
    return () => {
      typeof threadsSubscription === "function" ? threadsSubscription() : null;
    };
  }, [agentId, db, id, user]);

  return {
    threadList: threadList.sort((a, b) => {
      return b.created_at.toMillis() - a.created_at.toMillis();
    }),
    messagesList,
  };
};

export default useFirebaseAgentsData;
