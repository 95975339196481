import { AgentCreateBody } from "../../utils/types";
import { slugify } from "../../utils/utils";
import * as Sentry from "@sentry/nextjs";
export const runtime = "nodejs";
// This is required to enable streaming
export const dynamic = "force-dynamic";

export async function installAgent(agentId: string, authToken: string) {
  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/agent/install/${agentId}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "POST",
    headers: headers,
  };
  try {
    const response = await fetch(`${url}`, options);
    return response.json();
  } catch (e) {
    alert("Error installing agent, We have notified the developers about this");
    Sentry.captureException(e);
    return e;
  }
}

export async function getMe(authToken: string) {
  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/me`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "GET",
    headers: headers,
  };
  try {
    const response = await fetch(`${url}`, options);
    return response.json();
  } catch (e) {
    alert(
      "Error Getting the user profile from server, please contact support or try again"
    );
    Sentry.captureException(e);
    return e;
  }
}

export async function deleteToken(tokenId: string, authToken: string) {
  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/${tokenId}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "DELETE",
    headers: headers,
  };
  try {
    const response = await fetch(`${url}`, options);
    return response.json();
  } catch (e) {
    alert(
      "Error deleting this token, try again or contact support if the problem persists"
    );
    Sentry.captureException(e);
    return e;
  }
}

export async function createAPIToken(name: string, authToken: string) {
  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/create_token`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      meta: name,
    }),
  };
  try {
    const response = await fetch(`${url}`, options);
    return response.json();
  } catch (e) {
    alert(
      "Error creating this token, try again or contact support if the problem persists"
    );
    Sentry.captureException(e);
    return e;
  }
}

export async function createAgent(
  agentBody: AgentCreateBody,
  authToken: string
) {
  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/agent`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(agentBody),
  };
  try {
    const response = await fetch(`${url}`, options);
    return response.json();
  } catch (e) {
    alert("Error creating agent, We have notified the developers about this");
    Sentry.captureException(e);
    return e;
  }
}

export async function shareChat(
  agentId: string,
  threadId: string,
  agentName: string,
  agentImage: string,
  threadTitle: string,
  authToken: string
) {
  const slug = slugify(threadTitle);

  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/chat/share`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      agent_id: agentId,
      thread_id: threadId,
      agent_name: agentName,
      agent_image: agentImage,
      slug: slug,
    }),
  };
  try {
    const response = await fetch(`${url}`, options);
    return response.json();
  } catch (e) {
    alert("Error sharing chat, We have notified the developers about this");
    Sentry.captureException(e);
    return e;
  }
}

export async function AskAI(
  message: string,
  authToken: string,
  agentId: string,
  threadId: string,
  llm: string,
  addToMemory?: boolean,
  callbackStream = (chunk: string) => {}
): Promise<void> {
  const body = {
    input: message,
    agent_id: agentId,
    thread_id: threadId,
    is_memory: addToMemory ?? false,
    llm: llm,
  };

  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/chat/stream`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  };
  const response = await fetch(`${url}`, options);
  if (!response.ok) {
    throw new Error(response.statusText + " : " + response.status);
  }
  const reader = response.body?.getReader();
  if (reader) {
    while (true) {
      const result = await reader.read();
      if (result.done) {
        break;
      }
      const data = new TextDecoder().decode(result.value);
      callbackStream(data);
    }
  }
}

export async function updateAgentDescription(
  agentId: string,
  authToken: string,
  desc: string
) {
  const BASE_DOMAIN = process.env.NEXT_PUBLIC_API_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/api/v1/update-agent/${agentId}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      agent_desc: desc,
    }),
  };
  try {
    const response = await fetch(`${url}`, options);
    return response.json();
  } catch (e) {
    alert("Error updating agent description, Please try again");
    Sentry.captureException(e);
    return e;
  }
}
