import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import { useEffect, useState } from "react";
import useFirebaseFirestore from "./useFirebaseFirestore";
import { Agent } from "../utils/types";
import { useSession } from "../context/SessionContext";

const useFirebaseAiList = () => {
  const { db } = useFirebaseFirestore();
  const { user } = useSession();
  const [installAgentsPersonal, setInstallAgentsPersonal] = useState<Agent[]>(
    []
  );

  useEffect(() => {
    let aiSnap: Unsubscribe;
    if (user && db) {
      const data = collection(db, `/profiles/${user.uid}/agents`);
      aiSnap = onSnapshot(data, (snap) => {
        setInstallAgentsPersonal(
          snap.docs.map((document) => {
            return document.data() as Agent;
          })
        );
      });
    }
    return () => {
      typeof aiSnap === "function" ? aiSnap() : null;
    };
  }, [db, user]);

  return { installAgentsPersonal };
};

export default useFirebaseAiList;
