import { createContext, useContext, ReactNode, useState } from "react";
import useFirebaseLogin from "../hooks/useFirebaseLogin";
import { User } from "firebase/auth";

interface ISessionContext {
  loading: boolean;
  country: string;
  authToken: string | undefined;
  setCountry: (country: string) => void;
  signOut: () => void;
  fetchUser: () => User | null;
  signinWithGoogle: () => void;
  signinWithGithub: () => void;
  user: User | undefined;
}

const SessionContext = createContext<ISessionContext>({
  country: "US", //default country
  setCountry: () => {},
  signOut: () => {},
  fetchUser: () => null,
  authToken: undefined,
  signinWithGithub: () => {},
  signinWithGoogle: () => {},
  user: undefined,
  loading: false,
});

interface BaseLayoutProps {
  children?: ReactNode;
}

export const SessionContextProvider: React.FC<BaseLayoutProps> = ({
  children,
}) => {
  const [country, setCountry] = useState<string>("US");
  const {
    user,
    signinWithGoogle,
    signinWithGithub,
    signOut,
    fetchUser,
    authToken,
    loading,
  } = useFirebaseLogin();

  const setCountryName = (countryName: string) => {
    setCountry(countryName);
  };

  const ContextValues = {
    country,
    setCountry,
    user,
    signinWithGoogle,
    signinWithGithub,
    signOut,
    fetchUser,
    authToken,
    loading,
  };

  return (
    <SessionContext.Provider value={ContextValues}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
