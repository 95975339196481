import { ALLOWED_USER_LIST } from "./const";

export const isAllowedUser = (email?: string | undefined | null) => {
  if (!email) {
    return false;
  }
  return true;
  // if (ALLOWED_USER_LIST.includes(email)) {
  //   return true;
  // }

  // return false;
};

export const slugify = (str: string): string => {
  return str
    .replace(/[^a-zA-Z0-9]+/g, "-")
    .toLowerCase()
    .replace(/^-+|-+$/g, "");
};

export const getLanguageHljs = (className?: string): string | undefined => {
  const language = className?.split(" ").pop()?.replace("language-", "");
  return language;
};

export const renderTime = (time: any) => {
  const date = new Date(time.seconds * 1000 + time.nanoseconds / 1000000);
  return date.toLocaleString();
};

export const maskApiKey = (apiKey: string) => {
  return "api_" + "*".repeat(18) + apiKey.slice(-3);
};
