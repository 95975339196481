import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../services/firebase";
import { Agent } from "../utils/types";
import { useEffect, useState } from "react";
import { useSession } from "../context/SessionContext";

const useFirebaseFirestore = () => {
  const { user } = useSession();
  const [personalInstallAgents, setPersonalInstallAgents] = useState<
    Agent[] | null
  >(null);
  const [premiumInstallAgents, setPremiumInstallAgents] = useState<
    Agent[] | null
  >(null);

  useEffect(() => {
    if (!user) {
      return;
    }
    const getPersonalInstallAgents = async (uid: string) => {
      const querySnapshot = await getDocs(
        query(collection(db, "agents_to_install"), where("added_by", "==", uid))
      );
      const agents = querySnapshot.docs.map((doc) => doc.data());
      setPersonalInstallAgents(agents as Agent[]);
    };

    const getPremiumInstallAgents = async () => {
      const querySnapshot = await getDocs(
        query(
          collection(db, "agents_to_install"),
          where("is_premium", "==", true)
        )
      );
      const agents: Agent[] = querySnapshot.docs.map(
        (doc) => doc.data() as Agent
      );
      const agentsOrdered = agents.sort((a, b) => {
        return a.default && b.default ? 0 : a.default ? -1 : 1;
      });
      setPremiumInstallAgents(agentsOrdered);
    };

    getPersonalInstallAgents(user.uid);
    getPremiumInstallAgents();
  }, [user]);

  return { personalInstallAgents, premiumInstallAgents, db };
};

export default useFirebaseFirestore;
