import { AnalyticsEvent } from "../analytics.dto";
import { AnalyticsProvider } from "./base.provider";
import {
  getAnalytics,
  logEvent,
  setUserProperties,
  setUserId,
} from "firebase/analytics";

export class FirebaseAnalyticsProvider extends AnalyticsProvider {
  sendEvent(event: AnalyticsEvent) {
    const analytics = getAnalytics();
    logEvent(analytics, event.name, event.options);
  }

  setUserProperties(
    properties: Record<string, string | number | boolean>
  ): void {
    const analytics = getAnalytics();
    setUserProperties(analytics, properties);
  }

  setUserId(userId: string): void {
    const analytics = getAnalytics();
    setUserId(analytics, userId);
  }
}
