import {
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  User,
} from "firebase/auth";
import { useState, useEffect, useContext } from "react";
import { firebaseAuth } from "../services/firebase";
import { getMe } from "../pages/api/server";
import AnalyticsServiceContext from "../context/AnalyticsServiceContext";

const useFirebaseLogin = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>();
  const [authToken, setAuthToken] = useState<string | undefined>();
  const analyticsService = useContext(AnalyticsServiceContext);

  const signinWithGithub = () => {
    const provider = new GithubAuthProvider();
    signInWithPopup(firebaseAuth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential: any = GithubAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        setAuthToken(token);
        // The signed-in user info.
        const user = result.user;
        setUser(user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(error);
      });
  };

  const fetchUser = () => {
    const user = firebaseAuth.currentUser;
    if (user) {
      setUser(user);
    } else {
      setUser(undefined);
    }
    return user;
  };

  const signinWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(firebaseAuth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential: any = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        setAuthToken(token);
        // The signed-in user info.
        const user = result.user;
        setUser(user);
      })
      .catch((error) => {
        alert(error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  const signOut = () => {
    firebaseAuth
      .signOut()
      .then(() => {
        setUser(undefined);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    return firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then(async (idToken) => {
          // call server /me to create user profile
          const userProfile = await getMe(idToken);
          console.log("==> ", userProfile);
          setAuthToken(idToken);
          analyticsService.setUserId(user.uid);
          analyticsService.setUserProperties({
            name: user.displayName ?? "",
            email: user.email ?? "",
            photoURL: user.photoURL ?? "",
            uid: user.uid,
          });
        });
        setUser(user);
      } else {
        setUser(undefined);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }, []);

  return {
    user,
    signinWithGoogle,
    signinWithGithub,
    signOut,
    fetchUser,
    authToken,
    loading,
  };
};

export default useFirebaseLogin;
