import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import useFirebaseAgentsData from "../hooks/useFirebaseAgentsData";
import useFirebaseAiList from "../hooks/useFirebaseAiList";
import { Agent, ThreadStore } from "../utils/types";
import useFirebaseFirestore from "../hooks/useFirebaseFirestore";

interface IChatContext {
  messagesList: any[];
  threadList: ThreadStore[];
  installAgentsPersonal: Agent[];
  defaultAgent: Agent | null;
  premiumInstallAgents: Agent[] | null;
  refreshRequired?: boolean;
  setRefreshRequired?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatContext = createContext<IChatContext>({
  messagesList: [],
  threadList: [],
  defaultAgent: null,
  installAgentsPersonal: [],
  premiumInstallAgents: [],
  refreshRequired: false,
  setRefreshRequired: () => {},
});

interface BaseLayoutProps {
  children?: ReactNode;
}

export const ChatContextProvider: React.FC<BaseLayoutProps> = ({
  children,
}) => {
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
  const { messagesList, threadList } = useFirebaseAgentsData();
  const { installAgentsPersonal } = useFirebaseAiList();
  const { premiumInstallAgents } = useFirebaseFirestore();
  const [defaultAgent, setDefaultAgent] = useState<Agent | null>(null);

  const ContextValues = {
    messagesList,
    threadList,
    installAgentsPersonal,
    setRefreshRequired,
    refreshRequired,
    premiumInstallAgents,
    defaultAgent,
  };

  useEffect(() => {
    if (premiumInstallAgents) {
      setDefaultAgent(premiumInstallAgents.filter((a) => a.default)[0]);
    }
  }, [premiumInstallAgents]);

  return (
    <ChatContext.Provider value={ContextValues}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);
