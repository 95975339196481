import "../styles/globals.css";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import { LoaderContextProvider } from "../context/LoaderContext";
import { SessionContextProvider } from "../context/SessionContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChatContextProvider } from "../context/ChatContext";
import { useFirebase } from "../services/firebase";

function App({ Component, pageProps }: AppProps) {
  useFirebase();
  useEffect(() => {
    // setup analytics or any initialisation code when app starts here
  }, []);

  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider>
          <LoaderContextProvider>
            <ChatContextProvider>
              <Component {...pageProps} />
            </ChatContextProvider>
          </LoaderContextProvider>
        </SessionContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
