import { createContext, useContext, ReactNode, useState } from "react";

interface ILoaderContext {
  progress: number;
  setProgressBar: (progress: number) => void;
}

const LoaderContext = createContext<ILoaderContext>({
  progress: 0,
  setProgressBar: () => {},
});

interface BaseLayoutProps {
  children?: ReactNode;
}

export const LoaderContextProvider: React.FC<BaseLayoutProps> = ({
  children,
}) => {
  const [progress, setProgress] = useState<number>(0);

  const setProgressBar = (progress: number) => {
    setProgress(progress);
  };

  const ContextValues = {
    progress,
    setProgressBar,
  };

  return (
    <LoaderContext.Provider value={ContextValues}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);
